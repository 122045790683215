<template>
  <tr>
    <td class="text-xs-center" style="padding: 0px !important; min-width: 50px;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile
            @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
            v-prms="{'id': 'c59c7cd2', mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Editar Campanha</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{'id': '85c15047', mode: 'disabled'}" @click="duplicateCampaign()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">content_copy</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Duplicar Campanha</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{'id': '9e1b02ff', mode: 'disabled'}" @click="addChannels()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">mobile_friendly</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Adicionar canais</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{'id': '85c15047', mode: 'disabled'}" @click="listChannels()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">phonelink_setup</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Gerenciar canais</v-list-tile-title>
          </v-list-tile>
          <!-- <v-divider></v-divider>
          <v-list-tile v-prms="{'id': '85c15047', mode: 'disabled'}" @click="listChannels()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">download</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Exportar Contatos Enviados</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-prms="{'id': '85c15047', mode: 'disabled'}" @click="listChannels()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">download</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Exportar Contatos Enviados</v-list-tile-title>
          </v-list-tile> -->
        </v-list>
      </v-menu>
    </td>
    <td class="text-xs-left" style="padding: 0px !important; min-width: 70px;">
      <span>{{ campaign.id }}</span>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <v-icon v-if="campaign.type" small :color="campaign.type.color" class="pr-1">
        {{ campaign.type.icon }}
      </v-icon>
      <strong v-text="campaign.name"></strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important; max-width: 150px;">
      <div v-if="campaign.status" style="text-align: center;">
        <v-icon :color="campaign.status.color">
          {{ campaign.status.icon }}
        </v-icon>
        <br>
        <strong v-text="campaign.status.name"></strong>
      </div>
    </td>
    <td class="text-xs-center" style="padding: 0 10px !important; display: ruby-text;">
      <span style="display: flex; flex-direction: column; min-width: 220px; max-width: 280px; ">
        <div style="display: flex; flex-direction: column; justify-content: space-between;">
          <span>
            <span>Envios: </span>
            <strong>{{ campaign.total_sended }} de {{ campaign.total_contacts }}</strong>
            <span> ({{ campaign.progress }}%)</span>
          </span>
          <v-progress-linear
            v-model="campaign.progress"
            style="margin: 0 !important;"
            :color="campaign.status.color"
            :value="campaign.progress"
          ></v-progress-linear>
          <span>
            <v-icon small>done</v-icon>
            <strong> {{ campaign.messages_sended }} </strong>
            <v-icon small>done_all</v-icon>
            <strong> {{ campaign.messages_delivered }} </strong>
            <v-icon small color="blue">done_all</v-icon>
            <strong> {{ campaign.messages_seened }} </strong>
            <v-icon small color="red">manage_accounts</v-icon>
            <strong> {{ campaign.messages_error + campaign.messages_error_contact }} </strong>
            <v-icon small color="red">phonelink_erase</v-icon>
            <strong> {{ campaign.messages_error_channel }} </strong>
          </span>
        </div>
      </span>
    </td>
    <td class="text-xs-center" style="padding: 0 10px !important; max-width: 240px; min-width: 240px;">
      <v-btn v-prms="{id: '74e8cb98', mode: 'disabled'}" v-if="campaign.is_executable" @click="execute(campaign)" outline small color="green">
        <v-icon>play_arrow</v-icon>
        <span>Executar</span>
      </v-btn>
      <v-btn v-prms="{id: '4caaddce', mode: 'disabled'}" v-if="campaign.is_pausable" @click="pause(campaign)" outline small color="orange">
        <v-icon>pause</v-icon>
        <span>Pausar</span>
      </v-btn>
      <!-- <v-btn v-prms="{id: '1af2fe8f', mode: 'disabled'}" v-if="campaign.is_finishable" @click="stop(campaign)" outline small color="red">
        <v-icon>front_hand</v-icon>
        <span>Encerrar</span>
      </v-btn> -->
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn v-prms="{id: '74e8cb98', mode: 'disabled'}" v-on="on" icon @click="calculateStatistics(campaign)" small>
            <v-icon>published_with_changes</v-icon>
          </v-btn>
        </template>
        <span>
          Atualizar estatísticas desta campanha
        </span>
      </v-tooltip>
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn
            icon link small v-on="on"
            :to="{
              name: 'talk-list',
              params: { page: 'talk' },
              query: { where: `talks.talk_campaign_id,${campaign.id}` }
            }"
          >
            <v-icon>checklist</v-icon>
          </v-btn>
        </template>
        <span>
          Listar atendimentos desta campanha
        </span>
      </v-tooltip>
      <v-tooltip v-if="campaign.show_details" bottom lazy>
        <template v-slot:activator="{ on }">
          <v-btn
            icon link small v-on="on"
            :to="{
              name: 'talk-campaign',
              params: { id: campaign.id }
            }"
          >
            <v-icon>info_outline</v-icon>
          </v-btn>
        </template>
        <span>
          Detalhes da campanha
        </span>
      </v-tooltip>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'TalkMenus',
    computed: {
      editData () {
        delete this.props.item.status_id
        return this.props
      },
      campaign () {
        return this.props.item
      }
    },
    methods: {
      execute: function (campaign) {
        this.$WiApiPost({
          uri: `talks/campaign/${campaign.id}/execute`,
          data: {},
          config: {
            msgLoad: {
              title: `Inicializando a campanha "${campaign.name}"...`,
              text: 'Aguarde enquanto a campanha é inicializada.'
            },
            msgSucess: {
              title: 'Campanha inicializada com sucesso',
              text: 'O comando de inicialização foi enviado com sucesso.'
            },
            msgError: {
              title: 'Erro ao inicializar a campanha',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja inicializar a campanha?`,
              text: 'Um comando de inicialização será enviado para a campanha.'
            }
          },
          callback: () => {
            this.config.refresh()
          }
        })
      },
      pause: function (campaign) {
        this.$WiApiPost({
          uri: `talks/campaign/${campaign.id}/pause`,
          data: {},
          config: {
            msgLoad: {
              title: `Pausando a campanha...`,
              text: 'Aguarde enquanto a campanha é pausada.'
            },
            msgSucess: {
              title: 'Campanha pausada com sucesso',
              text: 'O comando de pausa foi enviado com sucesso.'
            },
            msgError: {
              title: 'Erro ao pausar a campanha',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja pausar a campanha?`,
              text: 'Um comando de pausa será enviado para a campanha.'
            }
          },
          callback: () => {
            this.config.refresh()
          }
        })
      },
      stop: function (campaign) {
        this.$WiApiPost({
          uri: `talks/campaign/${campaign.id}/stop`,
          data: {},
          config: {
            msgLoad: {
              title: `Encerrando a campanha...`,
              text: 'Aguarde enquanto a campanha é encerrada.'
            },
            msgSucess: {
              title: 'Campanha encerrada com sucesso',
              text: 'O comando de encerramento foi enviado com sucesso.'
            },
            msgError: {
              title: 'Erro ao encerrar a campanha',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja encerrar a campanha?`,
              text: 'Um comando de encerramento será enviado para a campanha.'
            }
          },
          callback: () => {
            this.config.refresh()
          }
        })
      },
      calculateStatistics: function (campaign) {
        this.$WiApiPost({
          uri: `talks/campaign/${campaign.id}/calculate-statistics`,
          data: {},
          config: {
            msgLoad: {
              title: `Calculando estatísticas da campanha...`,
              text: 'Aguarde um momento por favor.'
            },
            msgSucess: {
              title: 'Estatísticas calculadas com sucesso',
              text: 'As estatísticas foram calculadas com sucesso.'
            },
            msgError: {
              title: 'Erro ao calcular estatísticas',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Calcular as estatísticas da campanha?`,
              text: 'As estatísticas serão calculadas e atualizadas.'
            }
          },
          callback: () => {
            this.config.refresh()
          }
        })
      },
      listChannels: function () {
        this.$WiListDialog({
          wiConfig: 'talk-campaign-channel',
          filter: { talk_campaign_id: this.campaign.id },
          wiEditData: { talk_campaign_id: this.campaign.id },
          callback: () => {
            this.config.refresh()
          }
        })
      },
      addChannels: function () {
        this.$WiListDialog({
          wiConfig: 'talk-channel-select',
          mode: 'select',
          callback: selected => {
            let channels = selected
            if (!Array.isArray(selected)) {
              channels = [selected]
            }
            if (selected && channels.length) {
              this.$WiApiPost({
                uri: `talks/campaign-channels`,
                data: {
                  talk_campaign_id:this.campaign.id,
                  channels: channels.map(channel => channel.id)
                },
                config: {
                  msgLoad: {
                    title: `Adicionando canais à campanha "${this.campaign.name}"...`,
                    text: 'Aguarde enquanto os canais são adicionados.'
                  },
                  msgSucess: {
                    title: 'Canais adicionados com sucesso',
                    text: 'Os canais foram adicionados com sucesso.'
                  },
                  msgError: {
                    title: 'Erro ao adicionar canais',
                    text: 'Tente novamente ou contacte o suporte.'
                  }
                },
                callback: () => {
                  this.config.refresh()
                }
              })
            }
          }
        })
      },
      duplicateCampaign: function () {
        this.$WiEditDialog({
          wiConfig: 'talk-campaign',
          data: {
            name: this.campaign.name,
            type_id: this.campaign.type_id,
            is_active: true,
            size_batch: this.campaign.size_batch,
            wait_batche: this.campaign.wait_batche,
            wait_min: this.campaign.wait_min,
            wait_max: this.campaign.wait_max,
            talk_channels: this.campaign.channels.map(channel => channel.id),
            tags_included: this.campaign.tags_included,
            tags_not_included: this.campaign.tags_not_included,
            file_id: this.campaign.file_id,
            message_1: this.campaign.message_1,
            message_2: this.campaign.message_2,
            message_3: this.campaign.message_3,
            message_4: this.campaign.message_4,
            reply_as_chatbot: this.campaign.talk_menu_id ? true : false,
            talk_menu_id: this.campaign.talk_menu_id,
            menu: this.campaign.menu
          },
          onSubmit: () => {
            this.config.refresh()
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>